<template>
  <div>
    <HomePageTop />
    <HomeDownloadAppDesktop
      v-if="$device.isDesktop"
      class="osk-mt-xxxl"
    >
      <template #qr-code>
        <img
          src="~assets/img/webToApp/qr_main_gender.webp"
          alt="qr_code"
          height="134px"
          width="134px"
        >
      </template>
    </HomeDownloadAppDesktop>
    <HomeServiceWork />
    <HomeCommunity />
    <HomeBenefits />
    <HomeCelebrity />
    <BusinessProductsOskellyChoice />
    <HomeDownloadAppMobile v-if="$device.isMobile" />

    <OCommunityDialog
      v-model="isCommunityDialogOpen"
      :owner-mode="communityDialogOwnerMode"
      :user="communityDialogOwnerMode && isAuth ? account : undefined"
    />

    <WebToAppDownloadAppDesktopDialog
      v-if="$device.isDesktop"
      v-model="isWebToAppDownloadAppDesktopDialogOpen"
    >
      <template #qr-code>
        <img
          src="~assets/img/webToApp/qr_main_timeout.webp"
          height="124"
          width="124"
          alt="qr_code"
        >
      </template>
    </WebToAppDownloadAppDesktopDialog>
  </div>
</template>

<script lang="ts" setup>
import OCommunityDialog from '~/components/account/OCommunityDialog.vue';
import useWebToApp from '~/composables/useWebToApp';
import { LS_KEY_WEB_TO_APP_DOWNLOAD_APP_DESKTOP_DIALOG, LS_KEY_WEB_TO_APP_GENDER_DIALOG } from 'assets/constants';

definePageMeta({
  middleware: 'gender',
});

const schemaOrg = useSchemaOrg();

useHead({
  bodyAttrs: {
    class: 'oskelly-2_0',
  },
  title: 'Oskelly - интернет-магазин брендовой одежды, обуви и аксессуаров от бутиков и частных продавцов – Интернет-магазин Oskelly',
  meta: [
    {
      name: 'description',
      content: 'Oskelly - интернет-магазин брендовой одежды, обуви и аксессуаров от бутиков и частных продавцов. Покупайте более 500 брендов со скидками до 90%. Продавайте быстро и безопасно. Подарочная упаковка и доставка по Москве и всей России.',
    },
  ],
  script: [
    {
      type: 'application/ld+json',
      children: schemaOrg,
    },
  ],
});

const { promoCode, sale } = useWebToApp();
const { account, isAuth } = useAccountStore();
const route = useRoute();
const showWebToAppDownloadAppDesktopDialog = useLocalStorage(LS_KEY_WEB_TO_APP_DOWNLOAD_APP_DESKTOP_DIALOG, true);
const isCommunityDialogOpen = ref(false);
const isWebToAppDownloadAppDesktopDialogOpen = ref(false);
const communityDialogOwnerMode = ref(false);
let showWebToAppDownloadAppDesktopDialogTimeoutId = 0;

onMounted(() => {
  if (showWebToAppDownloadAppDesktopDialog.value) {
    showWebToAppDownloadAppDesktopDialogTimeoutId = window.setTimeout(() => {
      isWebToAppDownloadAppDesktopDialogOpen.value = true;
      showWebToAppDownloadAppDesktopDialog.value = false;
    }, 90000);
  }

  if (!isDefined(route.meta.communityDialog)) return;

  communityDialogOwnerMode.value = isDefined(route.meta.communityDialog.ownerMode)
    ? route.meta.communityDialog.ownerMode
    : Boolean(isAuth.value && account.value.communityBadge?.status?.code);

  isCommunityDialogOpen.value = true;
});

onUnmounted(() => clearTimeout(showWebToAppDownloadAppDesktopDialogTimeoutId));
</script>
