<template>
  <section class="osk-community">
    <div class="osk-wrapper">
      <h2 class="osk-community__title">
        {{ $t('components.home.community.title') }}
      </h2>

      <UikitSwiper
        navigation
        pagination
        off
        loop
        :autoplay="{
          delay: 4000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }"
        :breakpoints="sliderBreakpoints"
        arrow-back
        :css-mode="!isDesktop"
        class="osk-community__swiper"
      >
        <UikitSwiperSlide>
          <div class="osk-community__image">
            <template v-if="!isInternational">
              <picture>
                <source
                  type="image/webp"
                  srcset="~assets/img/home/comments-mob.webp"
                  media="(max-width: 1023px)"
                >
                <img
                  src="~assets/img/home/comments.webp"
                  class="osk-community__image-img"
                  alt="comments"
                  loading="lazy"
                >
              </picture>
            </template>

            <template v-else>
              <picture>
                <source
                  type="image/webp"
                  srcset="~assets/img/home/comments-mob-int.webp"
                  media="(max-width: 1023px)"
                >
                <img
                  src="~assets/img/home/comments-int.webp"
                  class="osk-community__image-img"
                  alt="comments"
                  loading="lazy"
                >
              </picture>
            </template>
          </div>

          <div class="osk-community__block">
            <div class="osk-community__block-title">
              {{ $t('components.home.community.block1.title') }}
            </div>

            <div class="osk-community__block-text">
              {{ $t('components.home.community.block1.text') }}
            </div>
          </div>
        </UikitSwiperSlide>

        <UikitSwiperSlide>
          <div class="osk-community__image">
            <template v-if="!isInternational">
              <picture>
                <source
                  type="image/webp"
                  srcset="~assets/img/home/price-mob.webp"
                  media="(max-width: 1023px)"
                >
                <img
                  src="~assets/img/home/price.webp"
                  class="osk-community__image-img"
                  alt="price"
                  loading="lazy"
                >
              </picture>
            </template>

            <template v-else>
              <picture>
                <source
                  type="image/webp"
                  srcset="~assets/img/home/price-mob-int.webp"
                  media="(max-width: 1023px)"
                >
                <img
                  src="~assets/img/home/price-int.webp"
                  class="osk-community__image-img"
                  alt="price"
                  loading="lazy"
                >
              </picture>
            </template>
          </div>

          <div class="osk-community__block">
            <div class="osk-community__block-title">
              {{ $t('components.home.community.block2.title') }}
            </div>

            <div class="osk-community__block-text">
              {{ $t('components.home.community.block2.text') }}
            </div>
          </div>
        </UikitSwiperSlide>

        <UikitSwiperSlide>
          <div class="osk-community__image">
            <template v-if="!isInternational">
              <picture>
                <source
                  type="image/webp"
                  srcset="~assets/img/home/streams-mob.webp"
                  media="(max-width: 1023px)"
                >
                <img
                  src="~assets/img/home/streams.webp"
                  class="osk-community__image-img"
                  alt="streams"
                  loading="lazy"
                >
              </picture>
            </template>

            <template v-else>
              <picture>
                <source
                  type="image/webp"
                  srcset="~assets/img/home/streams-mob-int.webp"
                  media="(max-width: 1023px)"
                >
                <img
                  src="~assets/img/home/streams-int.webp"
                  class="osk-community__image-img"
                  alt="streams"
                  loading="lazy"
                >
              </picture>
            </template>
          </div>

          <div class="osk-community__block">
            <div class="osk-community__block-title">
              {{ $t('components.home.community.block3.title') }}
            </div>

            <div class="osk-community__block-text">
              {{ $t('components.home.community.block3.text') }}
            </div>
          </div>
        </UikitSwiperSlide>

        <UikitSwiperSlide>
          <div class="osk-community__image">
            <template v-if="!isInternational">
              <picture>
                <source
                  type="image/webp"
                  srcset="~assets/img/home/stories-mob.webp"
                  media="(max-width: 1023px)"
                >
                <img
                  src="~assets/img/home/stories.webp"
                  class="osk-community__image-img"
                  alt="stories"
                  loading="lazy"
                >
              </picture>
            </template>

            <template v-else>
              <picture>
                <source
                  type="image/webp"
                  srcset="~assets/img/home/stories-mob-int.webp"
                  media="(max-width: 1023px)"
                >
                <img
                  src="~assets/img/home/stories-int.webp"
                  class="osk-community__image-img"
                  alt="stories"
                  loading="lazy"
                >
              </picture>
            </template>
          </div>

          <div class="osk-community__block">
            <div class="osk-community__block-title">
              {{ $t('components.home.community.block4.title') }}
            </div>

            <div class="osk-community__block-text">
              {{ $t('components.home.community.block4.text') }}
            </div>
          </div>
        </UikitSwiperSlide>

        <UikitSwiperSlide>
          <div class="osk-community__image">
            <template v-if="!isInternational">
              <picture>
                <source
                  type="image/webp"
                  srcset="~assets/img/home/wish-mob.webp"
                  media="(max-width: 1023px)"
                >
                <img
                  src="~assets/img/home/wish.webp"
                  class="osk-community__image-img"
                  alt="wish"
                  loading="lazy"
                >
              </picture>
            </template>

            <template v-else>
              <picture>
                <source
                  type="image/webp"
                  srcset="~assets/img/home/wish-mob-int.webp"
                  media="(max-width: 1023px)"
                >
                <img
                  src="~assets/img/home/wish-int.webp"
                  class="osk-community__image-img"
                  alt="wish"
                  loading="lazy"
                >
              </picture>
            </template>
          </div>

          <div class="osk-community__block">
            <div class="osk-community__block-title">
              {{ $t('components.home.community.block5.title') }}
            </div>

            <div class="osk-community__block-text">
              {{ $t('components.home.community.block5.text') }}
            </div>
          </div>
        </UikitSwiperSlide>
      </UikitSwiper>
    </div>
  </section>
</template>

<script lang="ts" setup>
const sliderBreakpoints = {
  0: {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 16,
    navigation: { enabled: true },
    pagination: { enabled: true },
  },
  767: {
    spaceBetween: 24,
    navigation: { enabled: false },
    pagination: { enabled: true },
  },
};

const { isInternational } = useInternational();
const { isDesktop } = useUiBreakpoints();
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-community {
  &__title {
    @include font-heading;
    padding-bottom: 18px;

    @include media-query(md-and-up) {
      text-transform: uppercase;
      text-align: center;
      padding-top: 50px;
      padding-bottom: 32px;
    }
  }

  :deep(.swiper-slide) {
    display: flex;
    flex-direction: column;
  }

  &__swiper {
    margin: 0 -16px;

    @include media-query(md-and-up) {
      margin: 0;
    }

    :deep(.swiper-pagination-bullet) {
      width: 6px;
      height: 6px;
      margin: 0 4px !important;
    }

    :deep(.osk-swiper-button-prev) {
      top: 339px;
      left: 12px;
      right: auto;

      .osk-icon {
        width: 17px !important;
        height: 17px !important;
      }
    }

    :deep(.osk-swiper-button-next) {
      top: 339px;
      right: 12px;

      .osk-icon {
        width: 17px !important;
        height: 17px !important;
      }
    }

    :deep(.swiper-pagination) {
      bottom: auto;
      top: 344px;
      display: flex;
      justify-content: center;

      @include media-query(md-and-up) {
        left: -4px;
        top: 300px;
        width: auto;
      }

      @include media-query(lg-and-up) {
        top: 313px;
      }
    }
  }

  &__image {
    width: 100%;
    height: 375px;

    @include media-query(md-and-up) {
      width: 50%;
      border-radius: 4px;
      overflow: hidden;
    }

    @include media-query(lg-and-up) {
      height: 486px;
    }
  }

  &__image-img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  :deep(.swiper-slide) {
    @include media-query(md-and-up) {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  &__block {
    padding: 0 $indent-medium;

    @include media-query(md-and-up) {
      flex-grow: 0;
      width: 50%;
      padding: 0;
      padding-right: $indent-medium;
      padding-top: 167px;
    }
  }

  &__block-title {
    @include font-style($font-size-subheading, $line-height-subheading, $font-weight-bold);
    margin-top: $indent-large;
    margin-bottom: $indent-medium;

    @include media-query(md-and-up) {
      @include font-style($font-size-heading, $line-height-heading, $font-weight-bold);
      margin-bottom: $indent-mlarge;
      margin-top: 0;
      text-transform: uppercase;
    }
  }

  &__block-text {
    line-height: 20px;
    max-width: 436px;
  }
}
</style>
