<template>
  <section
    class="osk-home-app-mobile osk-pt-md"
    :class="isInternational ? 'osk-home-app-mobile_international' : 'osk-home-app-mobile_not-international'"
  >
    <div class="osk-wrapper">
      <h2 class="text-heading osk-py-sm text-center">
        {{ $t('home.downloadAppMobile.header') }}
      </h2>

      <div class="osk-mt-cp">
        <div class="osk-home-app-mobile__text">
          {{ $t('home.downloadAppMobile.text') }}
        </div>

        <UikitButton
          class="osk-mt-lg"
          to="https://oskelly.onelink.me/kabM/0qb0xthp"
          target="_blank"
          rel="noopener noreferrer"
          full-width
          outline
        >
          {{ $t('home.downloadAppMobile.downloadBtn') }}
        </UikitButton>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
const { isInternational } = useInternational();
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-home-app-mobile {
  height: 487px;

  &_international {
    background: url('@/assets/img/home/home-app-mobile-int.webp') no-repeat center/100%;
  }

  &_not-international {
    background: url('@/assets/img/home/home-app-mobile.webp') no-repeat center/100%;
  }

  &__text {
    margin: 0 auto;
    max-width: 343px;
    text-align: center;
  }
}
</style>
